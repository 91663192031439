import React from 'react'

const Navbar = React.lazy(() => import('../common/navbar/Navbar'))
const Footer = React.lazy(() => import('../common/Footer'))

const Layout = ({
  children,
  pathname = '',
  hideNavbar = false,
  hideFooter = false,
}) => {
  // Maintenance Page
  // return(
  //   <div className="container w-full h-screen flex flex-col gap-2 justify-center items-center">
  //       <img src={'/images/maintenance.jpg'} alt="" className="justify-center items-center w-full md:w-1/2"/>
  //   </div>
  // )
  return (
    <>
      {!hideNavbar && <Navbar pathname={pathname} />}
      <main className="pt-24">{children}</main>
      {!hideFooter && <Footer />}
    </>
  )
}

export default Layout
